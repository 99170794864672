import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-outlet-product-list',
  templateUrl: './outlet-product-list.component.html',
  styleUrls: ['./outlet-product-list.component.css']
})
export class OutletProductListComponent implements OnInit {

  productStructure = [
    {
      id: 102,
      imageName: './assets/outlet/dezza_poltrona_frau_pelle_Sc_e_cavallino.png',
      imageAlt: 'Dezza Poltrona Frau pelle Sc e cavallino',
      productName: 'Dezza Poltrona Frau pelle Sc e cavallino',
      prezzoDiListino: '4965€',
      realPrice: '3000€ (sconto 40%)',
      isSold: false
    },
    {
      id: 103,
      imageName: './assets/outlet/dezza_poltrona_frau_pelle_velvety.png',
      imageAlt: 'Dezza Poltrona Frau pelle Velvety',
      productName: 'Dezza Poltrona Frau pelle Velvety',
      prezzoDiListino: '6040€',
      realPrice: '3900€ (sconto 35%)',
      isSold: false
    },
    {
      id: 106,
      imageName: './assets/outlet/divano_happy_jack_poltorna_frau.png',
      imageAlt: 'Divano Happy Jack Poltrona Frau',
      productName: 'Divano Happy Jack Poltrona Frau',
      prezzoDiListino: '8640€',
      realPrice: '5800€ (sconto 33%)',
      isSold: false
    },
    {
      id: 108,
      imageName: './assets/outlet/divano_otium_maxalto.png',
      imageAlt: 'Divano Otium Maxalto',
      productName: 'Divano Otium Maxalto',
      prezzoDiListino: '10398€',
      realPrice: '7300€ (sconto 30%)',
      isSold: false
    },
    {
      id: 109,
      imageName: './assets/outlet/la_belle_etoile_large_slamp.png',
      imageAlt: 'La Belle Etoile Large Slamp',
      productName: 'La Belle Etoile Large Slamp',
      prezzoDiListino: '800€',
      realPrice: '600€ (sconto 25%)',
      isSold: false
    },
    {
      id: 109,
      imageName: './assets/outlet/lampadario_blanca_panzeri.png',
      imageAlt: 'Lampadario Blanca Panzeri',
      productName: 'Lampadario Blanca Panzeri',
      prezzoDiListino: '1348€',
      realPrice: '1080€ (sconto 20%)',
      isSold: false
    },
    {
      id: 111,
      imageName: './assets/outlet/lampadario_bubble_s_riflessi.png',
      imageAlt: 'Lampadario Bubble S Riflessi',
      productName: 'Lampadario Bubble S Riflessi',
      prezzoDiListino: '964€',
      realPrice: '675€ (sconto 30%)',
      isSold: false
    },
    {
      id: 112,
      imageName: './assets/outlet/lampadario_coral_david_trubridge.png',
      imageAlt: 'Lampadario Coral David Trubridge',
      productName: 'Lampadario Coral David Trubridge',
      prezzoDiListino: '1300€',
      realPrice: '820€ (sconto 37%)',
      isSold: false
    },
    {
      id: 113,
      imageName: './assets/outlet/lampadario_kalatos_ceiling_slamp.png',
      imageAlt: 'Lampadario Kalatos Ceiling Slamp',
      productName: 'Lampadario Kalatos Ceiling Slamp',
      prezzoDiListino: '959€',
      realPrice: '720€ (sconto 25%)',
      isSold: false
    },
    {
      id: 115,
      imageName: './assets/outlet/lampadario_soffi_poltrona_frau_dim_media_e_piccola.png',
      imageAlt: 'Lampadario Soffi Poltrona Frau Dim. Media e Piccola',
      productName: 'Lampadario Soffi Poltrona Frau Dim. Media e Piccola',
      prezzoDiListino: '1670€ - 1270€',
      realPrice: '840€ - 640€ (sconto 50%)',
      isSold: false
    },
    {
      id: 119,
      imageName: './assets/outlet/sedie_bontempi_mood.png',
      imageAlt: 'Sedie Bontempi Mood (in diversi colori)',
      productName: 'Sedie Bontempi Mood (in diversi colori)',
      prezzoDiListino: '282€',
      realPrice: '184€ (sconto 35%)',
      isSold: false
    },
    {
      id: 120,
      imageName: './assets/outlet/tavolini_bob_poltrona_frau.png',
      imageAlt: 'Tavolino Bob Poltrona Frau',
      productName: 'Tavolino Bob Poltrona Frau',
      prezzoDiListino: '1390€',
      realPrice: '850€ (sconto 38%)',
      isSold: false
    },
    {
      id: 124,
      imageName: './assets/outlet/VL45_radiohus_louis_poulsen_dim.25cm_e_37cm.png',
      imageAlt: 'VL45 Radiohus Louis Poulsen diam. 25cm e 37cm',
      productName: 'VL45 Radiohus Louis Poulsen diam. 25cm e 37cm',
      prezzoDiListino: '535€ - 710€',
      realPrice: '455€ - 640€ (sconto 15-10%)',
      isSold: false
    },
    {
      id: 2,
      imageName: './assets/outlet/cucina-Indada-Dada.png',
      imageAlt: '',
      productName: 'Cucina Indada Dada Penisola da cm. 310.6*101.6pr',
      prezzoDiListino: '19.365€',
      realPrice: '11.500€ (sconto 41%)',
      isSold: false
    },
    {
      id: 27,
      imageName: './assets/outlet/cucina_ratio_dada_isola_in_travertino.png',
      imageAlt: 'Cucina Ratio Dada Top in travertino rapolano e ante in eucalipto',
      productName: 'Cucina Ratio Dada Top in travertino rapolano e ante in eucalipto',
      prezzoDiListino: '50.900 €',
      realPrice: '25.450 € (sconto 50%)',
      isSold: false
    },
    {
      id: 34,
      imageName: './assets/outlet/cabina_armadio_molteni_a_corpo.png',
      imageAlt: 'Cabina Armadio Molteni a corpo',
      productName: 'Cabina Armadio Molteni a corpo',
      prezzoDiListino: '4.850€',
      realPrice: '2.670€ (sconto 45%)',
      isSold: false
    },
    {
      id: 39,
      imageName: './assets/outlet/cameretta_nardi_interni.png',
      imageAlt: 'Cameretta Nardi Interni',
      productName: 'Cameretta Nardi Interni',
      prezzoDiListino: '3.280€',
      realPrice: '1.640€ (sconto 50%)',
      isSold: false
    },
    {
      id: 41,
      imageName: './assets/outlet/comodino_in_vetro_con_antina.png',
      imageAlt: 'Comodino in vetro con antina con chiusura',
      productName: 'Comodino in vetro con antina con chiusura',
      prezzoDiListino: '1.080 €',
      realPrice: '540 € (sconto 50%)',
      isSold: false
    },
    {
      id: 53,
      imageName: './assets/outlet/ditre_divano_union.png',
      imageAlt: 'Ditre divano Union, 3 posti',
      productName: 'Ditre divano Union, 3 posti 206 x 108 cm + 1 base rettangolare mono- scocca',
      prezzoDiListino: '7782,00 €',
      realPrice: '4670€ (sconto 40%)',
      isSold: false
    },
    {
      id: 60,
      imageName: './assets/outlet/libreria_square.png',
      imageAlt: 'Libreria Square',
      productName: 'Libreria Square L.230x70',
      prezzoDiListino: '2285,00€',
      realPrice: '1370€ (sconto 40%)',
      isSold: false
    },
    {
      id: 63,
      imageName: './assets/outlet/sedia_porada_astrid.png',
      imageAlt: 'Sedia Porada Astrid',
      productName: 'Sedia Porada Astrid',
      prezzoDiListino: '1222,00 €',
      realPrice: '670€ (sconto 45%)',
      isSold: false
    },
    {
      id: 65,
      imageName: './assets/outlet/sedia_net_bontempi_nera.png',
      imageAlt: 'Sedia Net Bontempi nera',
      productName: 'Sedia Net Bontempi nera',
      prezzoDiListino: '220,00 €',
      realPrice: '132€ (sconto 40%)',
      isSold: false
    },
    {
      id: 68,
      imageName: './assets/outlet/cucina_presa_top_solide_iceland.png',
      imageAlt: 'CucinaPresa, Top solid surface Iceland, lavello saldato con colonne con elettrodomestici',
      productName: 'CucinaPresa, Top solid surface Iceland, lavello saldato con colonne con elettrodomestici',
      prezzoDiListino: '38970€',
      realPrice: '19485€ (sconto 50%)',
      isSold: false
    },
    {
      id: 79,
      imageName: './assets/outlet/comodino_juta_flou.png',
      imageAlt: 'Comodino Juta Flout laccato',
      productName: 'Comodino Juta Flout laccato',
      prezzoDiListino: '580€',
      realPrice: '470€ (sconto 20%)',
      isSold: false
    },
    {
      id: 82,
      imageName: './assets/outlet/sedia_outline_molteni.png',
      imageAlt: 'Sedia Outline Molteni con tessuto gamba cromo nero 4 pezzi',
      productName: 'Sedia Outline Molteni con tessuto gamba cromo nero 4 pezzi',
      prezzoDiListino: '870€',
      realPrice: '500€ (sconto 43%)',
      isSold: false
    },
    {
      id: 83,
      imageName: './assets/outlet/panca_tanit_creazioni_imbottita.png',
      imageAlt: 'Panca Tanit Creazioni imbottita',
      productName: 'Panca Tanit Creazioni imbottita',
      prezzoDiListino: '2300€',
      realPrice: '1150€ (sconto 50%)',
      isSold: false
    },
    {
      id: 84,
      imageName: './assets/outlet/comodino_domino_giro_san_giacomo.png',
      imageAlt: 'Comodino Domino Giro San Giacomo',
      productName: 'Comodino Domino Giro San Giacomo',
      prezzoDiListino: '3712€',
      realPrice: '2153€ (sconto 42%)',
      isSold: false
    },
    {
      id: 85,
      imageName: './assets/outlet/armadio_san_giacomo_scrigno.png',
      imageAlt: 'Armadio SanGiacomo Scrigno L. 360 cm, H. 255 cm con elemento cabina',
      productName: 'Armadio SanGiacomo Scrigno L. 360 cm, H. 255 cm con elemento cabina',
      prezzoDiListino: '8925€',
      realPrice: '5355€ (sconto 40%)',
      isSold: false
    },
    {
      id: 88,
      imageName: './assets/outlet/divano_paul_molteni.png',
      imageAlt: 'Divano Paul Molteni',
      productName: 'Divano Paul Molteni',
      prezzoDiListino: '13612€',
      realPrice: '10200€ (25%)',
      isSold: false
    },
    {
      id: 94,
      imageName: './assets/outlet/sedia_cattelan_magda.png',
      imageAlt: 'Sedie Cattelan Magda',
      productName: 'Sedie Cattelan Magda',
      prezzoDiListino: '1070€',
      realPrice: '690€ (sconto -35%)',
      isSold: false
    },
    {
      id: 95,
      imageName: './assets/outlet/specchio_riflessi120.png',
      imageAlt: 'Specchio Riflessi 120x120cm',
      productName: 'Specchio Riflessi 120x120cm',
      prezzoDiListino: '840€',
      realPrice: '590€ (sconto 30%)',
      isSold: false
    },
    {
      id: 97,
      imageName: './assets/outlet/specchio_riflessi190x190.png',
      imageAlt: 'Specchio Riflessi 190x190cm',
      productName: 'Specchio Riflessi 190x190cm',
      prezzoDiListino: '1765€',
      realPrice: '1230€ (sconto 30%)',
      isSold: false
    },
    {
      id: 98,
      imageName: './assets/outlet/specchio_bontempi.png',
      imageAlt: 'Specchio Bontempi',
      productName: 'Specchio Bontempi',
      prezzoDiListino: '882€',
      realPrice: '617€ (sconto 30%)',
      isSold: false
    },
    {
      id: 124,
      imageName: './assets/outlet/Divano Fiocco. Medium.png',
      imageAlt: 'Divano Fiocco Flou',
      productName: 'Divano Fiocco Flou',
      prezzoDiListino: '',
      realPrice: 'In Offerta',
      isSold: false
    },
    {
      id: 125,
      imageName: './assets/outlet/Letto Selene Medium.png',
      imageAlt: 'Letto Selene Maxalto ',
      productName: 'Letto Selene Maxalto ',
      prezzoDiListino: '4.435,00€',
      realPrice: '2.660,00€ (sconto 40%)',
      isSold: false
    },
    {
      id: 126,
      imageName: './assets/outlet/Sedie Febo Medium.png',
      imageAlt: 'Sedie Febo Maxalto',
      productName: 'Sedie Febo Maxalto',
      prezzoDiListino: '1.570,00€ ',
      realPrice: '950,00€ (sconto 40%)',
      isSold: false
    },
    {
      id: 127,
      imageName: './assets/outlet/Divano Palinfrasca Medium.png',
      imageAlt: 'Divano Palinfrasca outdoor Molteni',
      productName: 'Divano Palinfrasca outdoor Molteni',
      prezzoDiListino: '',
      realPrice: 'In Offerta',
      isSold: false
    },
    {
      id: 128,
      imageName: './assets/outlet/Tavolo Mateo Medium.png',
      imageAlt: 'Tavolo Mateo Molteni',
      productName: 'Tavolo Mateo Molteni',
      prezzoDiListino: '',
      realPrice: 'In Offerta',
      isSold: false
    },
    {
      id: 129,
      imageName: './assets/outlet/Sedie Devon Medium.png',
      imageAlt: 'Sedie Devon Molteni',
      productName: 'Sedie Devon Molteni',
      prezzoDiListino: '1.693,00€',
      realPrice: '1.100,00€ (sconto 35%)',
      isSold: false
    },
    {
      id: 130,
      imageName: './assets/outlet/Sedie Janet Medium.png',
      imageAlt: 'Sedie Janet Molteni',
      productName: 'Sedie Janet Molteni',
      prezzoDiListino: '1.578,00€ ',
      realPrice: '1.100,00€ (sconto 40%)',
      isSold: false
    },
    {
      id: 131,
      imageName: './assets/outlet/Tavolo mirage Medium.png',
      imageAlt: 'Tavolo Mirage Bontempi',
      productName: 'Tavolo Mirage Bontempi',
      prezzoDiListino: '5.208,00€',
      realPrice: '3.125,00€ (sconto 40%)',
      isSold: false
    },
    {
      id: 132,
      imageName: './assets/outlet/Sedia Isabel  Medium.png',
      imageAlt: 'Sedie Isabel Riflessi',
      productName: 'Sedie Isabel Riflessi',
      prezzoDiListino: '480,00€',
      realPrice: '312,00€ (sconto 35%)',
      isSold: false
    }
  ];

  constructor() {
  }

  ngOnInit(): void { }

}
